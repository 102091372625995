import { useCommonStore } from './common';

export const useInstituteStore = defineStore('institute', () => {
    const { $getApi } = useNuxtApp();
    const commonStore = useCommonStore();
    const { loading, sortCategoryType, sortCourseType } = storeToRefs(commonStore);

    ////////////////////////////////////////// Variables ////////////////////////////////////////////

    // Institute
    const institutes = ref([]);
    const institutesLoading = ref(false);
    const institutesTotal = ref(0);
    const institutesCurrentPage = ref(1);
    const institutesLastPage = ref(0);
    const allInstitutes = ref([]);
    const fixedInstitutes = ref([]);

    // Course
    const instituteCourses = ref([]);
    const instituteCoursesLoading = ref(false);
    const instituteCoursesTotal = ref(0);
    const instituteCoursesCurrentPage = ref(1);
    const instituteCoursesLastPage = ref(0);
    const instituteCoursesFees = ref({});

    // Search
    const instituteSearchKeyword = ref('');
    const resetInstituteSearchKeyword = () => {
        instituteSearchKeyword.value = '';
    };

    // Filter
    const instituteFilterCountries = ref([]);
    const instituteFilterInstitutes = ref([]);
    const instituteFilterSubjects = ref([]);
    const instituteFilterLevels = ref([]);
    const instituteFilterIntakeDates = ref([]);
    const instituteFilterPriceRange = ref([]);

    ////////////////////////////////////////// Functions ////////////////////////////////////////////

    // Handle query
    const handleQuery = (query, endpoint) => {
        let customQuery = query;
        if (instituteSearchKeyword.value) {
            customQuery += `&filter[name]=${instituteSearchKeyword.value}`;
        }
        if (instituteFilterCountries.value.length) {
            customQuery += `&filter[countries]=${instituteFilterCountries.value.map(el => el.id).join(',')}`;
        }
        if (instituteFilterInstitutes.value.length) {
            customQuery += `&filter[${endpoint === 'courses' ? 'institutes' : 'id'}]=${instituteFilterInstitutes.value
                .map(el => el.id)
                .join(',')}`;
        }
        if (instituteFilterLevels.value.length) {
            customQuery += `&filter[levels]=${instituteFilterLevels.value.map(el => el.id).join(',')}`;
        }
        if (instituteFilterIntakeDates.value.length) {
            customQuery += `&filter[intakeDates]=${instituteFilterIntakeDates.value.map(el => el.id).join(',')}`;
        }
        if (instituteFilterPriceRange.value[0]?.priceRange?.length) {
            customQuery += `&filter[minFees]=${instituteFilterPriceRange.value[0].priceRange[0]}&filter[maxFees]=${instituteFilterPriceRange.value[0].priceRange[1]}`;
        }
        return customQuery;
    };

    // Get Institutes
    const getInstitutes = async (page = 1, scrollPagination = false) => {
        // Prevent duplicate calls
        if (institutesLoading.value) return;

        if (scrollPagination) {
            institutesLoading.value = true;
        } else {
            loading.value = true;
        }

        try {
            let query = handleQuery(`institutes?per_page=12&page=${page}&include=country,city`);

            if (sortCategoryType.value === 'name') {
                query += `&sort=name`;
            }

            const res = await $getApi(query);
            if (page === 1) {
                institutes.value = [];
            }
            institutes.value.push(...(res.data?.data || []));
            institutesLastPage.value = res.data?.meta?.last_page || 0;
            institutesCurrentPage.value = res.data?.meta?.current_page || 0;
            institutesTotal.value = res.data?.meta?.total || 0;
        } catch (error) {
            console.error('Error fetching universities:', error);
        } finally {
            institutesLoading.value = false;
            loading.value = false;
        }
    };

    // Get all Institutes
    const getAllInstitutes = async (countries = [], keyword = '') => {
        let query = `institutes?per_page=10`;

        if (countries.length) {
            query += `&filter[countries]=${countries.map(el => el.id).join(',')}`;
        }
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }

        try {
            const res = await $getApi(query);
            if (keyword) {
                let value;
                value = [...res.data.data];
                return value;
            } else {
                allInstitutes.value = res.data?.data || [];
            }
        } catch (error) {
            console.error('Error fetching institutes:', error);
        }
    };

    // Get courses
    const getInstitutesCourses = async (page = 1, scrollPagination = false) => {
        // Prevent duplicate calls
        if (instituteCoursesLoading.value) return;

        if (scrollPagination) {
            instituteCoursesLoading.value = true;
        } else {
            loading.value = true;
        }

        try {
            let query = handleQuery(
                `english-courses?per_page=12&page=${page}&include=country,city,institute,currency`,
                'courses'
            );

            const res = await $getApi(query);

            if (page === 1) {
                instituteCourses.value = [];
            }

            if (sortCourseType.value === 'name') {
                query += `&sort=name`;
            }

            instituteCourses.value.push(...(res.data?.data || []));
            instituteCoursesLastPage.value = res.data?.meta?.last_page || 0;
            instituteCoursesCurrentPage.value = res.data?.meta?.current_page || 0;
            instituteCoursesTotal.value = res.data?.meta?.total || 0;
        } catch (error) {
            console.error('Error fetching institute courses:', error);
        } finally {
            instituteCoursesLoading.value = false;
            loading.value = false;
        }
    };

    // Get courses fees
    const getInstituteCoursesFees = async () => {
        // Prevent recall if already fetched
        if (Object.keys(instituteCoursesFees.value).length) return;

        try {
            const res = await $getApi(`english-course/fees-range`);
            instituteCoursesFees.value = res.data?.data || {};
        } catch (error) {
            console.error('Error fetching institute courses fees:', error);
        }
    };

    // Get fixed alphabetically institutes for dropdown
    const getFixedInstitutes = async () => {
        if (fixedInstitutes.value.length) return;
        try {
            const res = await $getApi('institutes?per_page=5&include=country,city&sort=name');
            fixedInstitutes.value = res.data?.data || [];
        } catch (error) {
            console.error('Error fetching fixed institutes:', error);
        }
    };

    // Clear filter
    const instituteClearFilter = () => {
        instituteFilterCountries.value = [];
        instituteFilterInstitutes.value = [];
        instituteFilterSubjects.value = [];
        instituteFilterLevels.value = [];
        instituteFilterIntakeDates.value = [];
        instituteSearchKeyword.value = '';
        sortCategoryType.value = '';
        sortCourseType.value = '';
        instituteFilterPriceRange.value = [];
    };

    //Initial calls
    getInstitutes();
    getInstitutesCourses();
    getInstituteCoursesFees();

    return {
        institutes,
        institutesLoading,
        institutesCurrentPage,
        institutesLastPage,
        instituteCourses,
        institutesTotal,
        instituteCoursesTotal,
        instituteCoursesLoading,
        instituteCoursesCurrentPage,
        instituteFilterSubjects,
        instituteCoursesLastPage,
        instituteSearchKeyword,
        instituteFilterCountries,
        instituteFilterInstitutes,
        instituteFilterLevels,
        instituteFilterIntakeDates,
        allInstitutes,
        fixedInstitutes,
        instituteCoursesFees,
        instituteFilterPriceRange,
        instituteClearFilter,
        getInstitutes,
        getInstitutesCourses,
        getAllInstitutes,
        getFixedInstitutes,
        resetInstituteSearchKeyword,
    };
});

