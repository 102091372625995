import { useCommonStore } from './common';

export const useUniversityStore = defineStore('university', () => {
    const { $getApi } = useNuxtApp();
    const commonStore = useCommonStore();
    const { loading, sortCategoryType, sortCourseType } = storeToRefs(commonStore);

    ////////////////////////////////////////// Variables ////////////////////////////////////////////

    // University
    const universities = ref([]);
    const universitiesLoading = ref(false);
    const universitiesTotal = ref(0);
    const universitiesCurrentPage = ref(1);
    const universitiesLastPage = ref(0);
    const allUniversities = ref([]);
    const fixedUniversities = ref([]);

    // Course
    const universityCourses = ref([]);
    const universityCoursesLoading = ref(false);
    const universityCoursesTotal = ref(0);
    const universityCoursesCurrentPage = ref(1);
    const universityCoursesLastPage = ref(0);
    const universityCoursesFees = ref({});

    // Subject
    const universitySubjects = ref([]);

    // Search
    const universitySearchKeyword = ref('');
    const resetUniversitySearchKeyword = () => {
        universitySearchKeyword.value = '';
    };

    // filter
    const universityFilterCountries = ref([]);
    const universityFilterUniversities = ref([]);
    const universityFilterSubjects = ref([]);
    const universityFilterLevels = ref([]);
    const universityFilterIntakeDates = ref([]);
    const universityFilterPriceRange = ref([]);

    ////////////////////////////////////////// Functions ////////////////////////////////////////////

    // Handle query
    const handleQuery = (query, endpoint) => {
        let customQuery = query;
        if (universitySearchKeyword.value) {
            customQuery += `&filter[name]=${universitySearchKeyword.value}`;
        }
        if (universityFilterCountries.value.length) {
            customQuery += `&filter[countries]=${universityFilterCountries.value.map(el => el.id).join(',')}`;
        }
        if (universityFilterUniversities.value.length) {
            customQuery += `&filter[${
                endpoint === 'courses' ? 'universities' : 'id'
            }]=${universityFilterUniversities.value.map(el => el.id).join(',')}`;
        }
        if (universityFilterSubjects.value.length) {
            customQuery += `&filter[subjects]=${universityFilterSubjects.value.map(el => el.id).join(',')}`;
        }
        if (universityFilterLevels.value.length) {
            customQuery += `&filter[levels]=${universityFilterLevels.value.map(el => el.id).join(',')}`;
        }
        if (universityFilterIntakeDates.value.length) {
            customQuery += `&filter[intakeDates]=${universityFilterIntakeDates.value.map(el => el.id).join(',')}`;
        }
        if (universityFilterPriceRange.value[0]?.priceRange?.length) {
            customQuery += `&filter[minFees]=${universityFilterPriceRange.value[0].priceRange[0]}&filter[maxFees]=${universityFilterPriceRange.value[0].priceRange[1]}`;
        }
        return customQuery;
    };

    // Get universities
    const getUniversities = async (page = 1, scrollPagination = false) => {
        // Prevent duplicate calls
        if (universitiesLoading.value) return;

        if (scrollPagination) {
            universitiesLoading.value = true;
        } else {
            loading.value = true;
        }

        try {
            let query = handleQuery(`universities?per_page=12&page=${page}&include=country,city`);

            if (sortCategoryType.value === 'name') {
                query += `&sort=name`;
            }

            const res = await $getApi(query);
            if (page === 1) {
                universities.value = [];
            }
            universities.value.push(...(res.data?.data || []));
            universitiesLastPage.value = res.data?.meta?.last_page || 0;
            universitiesCurrentPage.value = res.data?.meta?.current_page || 0;
            universitiesTotal.value = res.data?.meta?.total || 0;
        } catch (error) {
            console.error('Error fetching universities:', error);
        } finally {
            universitiesLoading.value = false;
            loading.value = false;
        }
    };

    // Get all universities
    const getAllUniversities = async (countries = [], keyword = '') => {
        let query = `universities?per_page=10`;

        if (countries.length) {
            query += `&filter[countries]=${countries.map(el => el.id).join(',')}`;
        }
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }

        try {
            const res = await $getApi(query);
            if (keyword) {
                let value;
                value = [...res.data.data];
                return value;
            } else {
                allUniversities.value = res.data?.data || [];
            }
        } catch (error) {
            console.error('Error fetching universities:', error);
        }
    };

    // Get fixed top ranked universities for dropdown
    const getFixedUniversities = async () => {
        if (fixedUniversities.value.length) return;
        try {
            const res = await $getApi('universities?per_page=5&include=country,city&sort=ranking');
            fixedUniversities.value = res.data?.data || [];
        } catch (error) {
            console.error('Error fetching fixed universities:', error);
        }
    };

    // Get courses
    const getUniversityCourses = async (page = 1, scroll = false) => {
        // Prevent duplicate calls
        if (universityCoursesLoading.value) return;
        if (scroll) {
            universityCoursesLoading.value = true;
        } else {
            loading.value = true;
        }

        try {
            let query = handleQuery(
                `university-courses?per_page=12&page=${page}&include=country,city,university,universityCourseDates,currency,level,requirements`,
                'courses'
            );

            if (sortCourseType.value === 'name') {
                query += `&sort=name`;
            }

            const res = await $getApi(query);

            if (page === 1) {
                universityCourses.value = [];
            }
            universityCourses.value.push(...(res.data?.data || []));
            universityCoursesLastPage.value = res.data?.meta?.last_page || 0;
            universityCoursesCurrentPage.value = res.data?.meta?.current_page || 0;
            universityCoursesTotal.value = res.data?.meta?.total || 0;
        } catch (error) {
            console.error('Error fetching university courses:', error);
        } finally {
            universityCoursesLoading.value = false;
            loading.value = false;
        }
    };

    // Get courses fees
    const getUniversityCoursesFees = async () => {
        // Prevent recall if already fetched
        if (Object.keys(universityCoursesFees.value).length) return;

        try {
            const res = await $getApi(`university-courses/fees`);
            universityCoursesFees.value = res.data?.data || {};
        } catch (error) {
            console.error('Error fetching university courses fees:', error);
        }
    };

    // Get subject
    const getUniversitySubjects = async (keyword = '') => {
        let query = `subjects?per_page=10`;
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }
        try {
            const res = await $getApi(query);

            if (keyword) {
                let value;
                value = [...res.data.data];
                return value;
            } else {
                universitySubjects.value = res.data?.data || [];
            }
        } catch (error) {
            console.error('Error fetching university subjects:', error);
        }
    };

    // Clear filter
    const universityClearFilter = () => {
        universityFilterCountries.value = [];
        universityFilterUniversities.value = [];
        universityFilterSubjects.value = [];
        universityFilterLevels.value = [];
        universityFilterIntakeDates.value = [];
        universitySearchKeyword.value = '';
        sortCategoryType.value = '';
        sortCourseType.value = '';
        universityFilterPriceRange.value = [];
    };

    //Initial calls
    getUniversities();
    getUniversityCourses();
    getUniversitySubjects();
    getUniversityCoursesFees();

    return {
        universities,
        universityCourses,
        universitySubjects,
        universitiesTotal,
        universityCoursesTotal,
        universityCoursesLoading,
        universityCoursesCurrentPage,
        universityCoursesLastPage,
        universitiesLoading,
        universitiesCurrentPage,
        universitiesLastPage,
        universitySearchKeyword,
        universityFilterCountries,
        universityFilterUniversities,
        universityFilterSubjects,
        universityFilterLevels,
        universityFilterIntakeDates,
        allUniversities,
        fixedUniversities,
        universityCoursesFees,
        universityFilterPriceRange,
        universityClearFilter,
        getUniversities,
        getUniversityCourses,
        getAllUniversities,
        getUniversitySubjects,
        getFixedUniversities,
        resetUniversitySearchKeyword,
    };
});

